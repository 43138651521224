import { CLASSIC_EDITOR_MAIN_SECTION_WIDTH } from '../../../constants';

export const MEMBERS_MENU_CONTROLLER_TYPE = 'members-menu';

export const MENU_APP_WIDGET_CONNECTIONS = {
  type: 'ConnectionList',
  items: [
    {
      type: 'ConnectionItem',
      controllerId: 'members-menu-controller-id',
      isPrimary: true,
      config: '{}',
      role: 'members-menu',
    },
  ],
};

export const MENU_COMP_DEF_HORIZONTAL = {
  type: 'Component',
  data: {
    type: 'CustomMenuDataRef',
    menuRef: '#MEMBERS_SUB_MENU',
    role: 'members_menu',
  },
  componentType: 'wysiwyg.viewer.components.menus.DropDownMenu',
  layout: {
    width: 956,
    height: 40,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
    x: 0,
    y: 0,
  },
  props: {
    type: 'HorizontalMenuProperties',
    alignButtons: 'left',
    alignText: 'left',
    sameWidthButtons: false,
    stretchButtonsToMenuWidth: false,
    moreButtonLabel: 'More',
    moreItemHeight: 15,
  },
  style: {
    styleType: 'custom',
    type: 'ComponentStyle',
    skin: 'wysiwyg.common.components.dropdownmenu.viewer.skins.OverlineMenuButtonSkin',
    componentClassName: 'wysiwyg.viewer.components.menus.DropDownMenu',
    style: {
      // Theme or direct values
      properties: {
        'boxShadowToggleOn-shd': 'true',
        'alpha-bgDrop': '1',
        'alpha-brdh': '1',
        'alpha-brd': '0',
        'alpha-brds': '1',
        bgDrop: 'color_11',
        brd: 'color_15',
        brdh: 'color_17',
        brds: 'color_18',
        fnt: 'font_8',
        menuTotalBordersX: '0',
        pad: '10',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.2)',
        txt: 'color_15',
        txth: 'color_17',
        txts: 'color_18',
      },
      // Specifies whether value is taken from theme or directly
      propertiesSource: {
        'boxShadowToggleOn-shd': 'value',
        'alpha-bgDrop': 'value',
        'alpha-brd': 'value',
        'alpha-brdh': 'value',
        'alpha-brds': 'value',
        bgDrop: 'theme',
        brd: 'theme',
        brdh: 'theme',
        brds: 'theme',
        fnt: 'theme',
        menuTotalBordersX: 'value',
        pad: 'value',
        rd: 'value',
        shd: 'value',
        txt: 'theme',
        txth: 'theme',
        txts: 'theme',
      },
    },
  },
};

export const MENU_COMP_DEF_HORIZONTAL_RTL = {
  ...MENU_COMP_DEF_HORIZONTAL,
  props: {
    ...MENU_COMP_DEF_HORIZONTAL.props,
    itemsAlignment: 'right',
    alignButtons: 'right',
    alignText: 'right',
  },
};

export const MEMBERS_MENU_APP_WIDGET_PROPS = {
  componentType: 'platform.components.AppWidget',
  type: 'Container',
  style: {
    skin: 'platform.components.skins.AppWidgetSkin',
    style: {},
  },
  layout: {
    width: CLASSIC_EDITOR_MAIN_SECTION_WIDTH,
    height: 40,
    x: 12,
    y: 250,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  data: {
    type: 'AppController',
    applicationId: '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9',
    name: 'members-menu-controller',
    controllerType: MEMBERS_MENU_CONTROLLER_TYPE,
    id: 'members-menu-controller-id',
  },
};
