import { EditorSDK } from '@wix/platform-editor-sdk';

import { createBIService } from '../../utils/bi';
import { getIsResponsiveEditor } from '../services/applicationState';
import { APP_TOKEN } from '../constants';
import { Origin, ReferralInfo } from '../../types/bi';

interface OpenModalPanelProps<T = any> {
  editorSDK: EditorSDK;
  eventPayload?: T;
}

export interface OpenManagePanelPayload {
  pageRef?: unknown;
  referralInfo?: ReferralInfo;
}

export async function openManagePagesPanel({ eventPayload, editorSDK }: OpenModalPanelProps<OpenManagePanelPayload>) {
  const biService = await createBIService({ editorSDK });
  biService.managePagesAddMemberPagesClick({ origin: Origin.Editor, referralInfo: eventPayload?.referralInfo });
  await editorSDK.editor.openModalPanel(APP_TOKEN, {
    url: './assets/managePages.html',
    width: 1098,
    height: 696,
    shouldHideHeader: true,
    initialData: eventPayload,
  });
}

export async function openGeneralSettingsPanel({ editorSDK, eventPayload }: OpenModalPanelProps) {
  const isResponsiveEditor = getIsResponsiveEditor();
  const query = isResponsiveEditor ? '?origin=editor_x' : '';

  await editorSDK.editor.openModalPanel(APP_TOKEN, {
    url: `./assets/generalSettings.html${query}`,
    width: 876,
    height: 631,
    shouldHideHeader: true,
    initialData: eventPayload,
  });
}

export function openMemberPrivacySettingsDashboard(editorSDK: EditorSDK) {
  return editorSDK.editor.openDashboardPanel(APP_TOKEN, {
    url: '/member-privacy-settings/profile-privacy?referralInfo=editor_actions_menu',
    closeOtherPanels: false,
  });
}
