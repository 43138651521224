import { EditorSDK } from '@wix/platform-editor-sdk';
import { HttpClient } from '@wix/http-client';
import { APP_TOKEN } from '../editor-app-module/constants';

export const getHttpClient = async (editorSDK: EditorSDK, baseURL?: string) => {
  const instance = await editorSDK.document.info.getAppInstance(APP_TOKEN);
  const httpClient = new HttpClient({ baseURL, getAppToken: () => instance });

  return httpClient;
};
