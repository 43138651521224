import { APP_TOKEN } from '../constants';
import { EditorSDK } from '@wix/platform-editor-sdk';

function addApplication(editorSDK: EditorSDK, widgetDef: Parameters<EditorSDK['tpa']['add']['application']>[1]) {
  return editorSDK.tpa.add.application(APP_TOKEN, widgetDef);
}

function addComponent(editorSDK: EditorSDK, options: Parameters<EditorSDK['tpa']['add']['component']>[1]) {
  return editorSDK.tpa.add.component(APP_TOKEN, options);
}

function isApplicationInstalled({ editorSDK, appDefinitionId }: { editorSDK: EditorSDK; appDefinitionId: string }) {
  return editorSDK.tpa.isApplicationInstalled(APP_TOKEN, { appDefinitionId });
}

function isAppSectionInstalled({
  editorSDK,
  appDefinitionId,
  sectionId,
}: {
  editorSDK: EditorSDK;
  appDefinitionId: string;
  sectionId: string;
}) {
  return editorSDK.tpa.isAppSectionInstalled(APP_TOKEN, { appDefinitionId, sectionId });
}

function getDataByAppDefId({ editorSDK, appDefinitionId }: { editorSDK: EditorSDK; appDefinitionId: string }) {
  return editorSDK.tpa.app.getDataByAppDefId(APP_TOKEN, appDefinitionId);
}

function getAllCompsByApplicationId({
  editorSDK,
  applicationId,
}: {
  editorSDK: EditorSDK;
  applicationId: Parameters<EditorSDK['tpa']['app']['getAllCompsByApplicationId']>[1];
}) {
  return editorSDK.tpa.app.getAllCompsByApplicationId(APP_TOKEN, applicationId);
}

async function getAppDefIdByTpaAppId({
  editorSDK,
  tpaAppId,
}: {
  editorSDK: EditorSDK;
  tpaAppId: Parameters<EditorSDK['tpa']['app']['getAllCompsByApplicationId']>[1];
}) {
  const components = await editorSDK.tpa.app.getAllCompsByApplicationId('', tpaAppId);
  return components?.[0]?.appDefinitionId;
}

export {
  addApplication,
  addComponent,
  isApplicationInstalled,
  isAppSectionInstalled,
  getDataByAppDefId,
  getAllCompsByApplicationId,
  getAppDefIdByTpaAppId,
};
