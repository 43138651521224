import {
  MEMBERS_MENU_APP_WIDGET_PROPS,
  MENU_APP_WIDGET_CONNECTIONS,
  MENU_COMP_DEF_HORIZONTAL,
  MENU_COMP_DEF_HORIZONTAL_RTL,
} from './common-menu-layouts';
import { MENU_COMP_DEF, MENU_COMP_DEF_RTL } from '../../../constants';

const getClassicEditorMembersMenuDef = ({ isHorizontal, isRtl }: { isHorizontal: boolean; isRtl: boolean }) => {
  let compDef;
  let appWidgetProps;

  if (isHorizontal) {
    compDef = isRtl ? MENU_COMP_DEF_HORIZONTAL_RTL : MENU_COMP_DEF_HORIZONTAL;
    appWidgetProps = {
      ...MEMBERS_MENU_APP_WIDGET_PROPS,
      layout: {
        width: 956,
        height: 40,
        x: 12,
        y: 250,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
    };
  } else {
    compDef = isRtl ? MENU_COMP_DEF_RTL : MENU_COMP_DEF;
    appWidgetProps = {
      ...MEMBERS_MENU_APP_WIDGET_PROPS,
      layout: {
        x: 20,
        y: 275,
        width: 250,
        height: 94,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
    };
  }

  return {
    ...appWidgetProps,
    components: [
      {
        ...compDef,
        connections: MENU_APP_WIDGET_CONNECTIONS,
      },
    ],
  };
};

export { getClassicEditorMembersMenuDef };
