import { CLASSIC_EDITOR_MAIN_SECTION_WIDTH } from '../../../constants';

const SOSP_COMP_GRID_LAYOUT = {
  type: 'LayoutData',
  componentLayouts: [
    {
      type: 'ComponentLayout',
      width: { type: 'auto' },
      height: { type: 'auto' },
    },
  ],
  itemLayouts: [
    {
      type: 'GridItemLayout',
      gridArea: {
        rowStart: 1,
        columnStart: 1,
        rowEnd: 2,
        columnEnd: 2,
      },
      justifySelf: 'stretch',
      alignSelf: 'stretch',
      margins: {
        left: { type: 'px', value: 0 },
        top: { type: 'px', value: 0 },
        right: { type: 'px', value: 0 },
        bottom: { type: 'px', value: 30 },
      },
    },
  ],
  containerLayouts: [
    {
      type: 'GridContainerLayout',
      rows: [
        { type: 'fr', value: 1 },
        { type: 'px', value: 40 },
      ],
      columns: [{ type: 'fr', value: 1 }],
    },
  ],
};

const SOSP_COMP_RESPONSIVE_COMP_DEF = {
  componentType: 'wysiwyg.viewer.components.SiteRegionContainer',
  type: 'Container',
  components: [],
  activeModes: {},
  layoutResponsive: SOSP_COMP_GRID_LAYOUT,
  layout: {
    x: 0,
    y: 0,
    fixedPosition: false,
    width: CLASSIC_EDITOR_MAIN_SECTION_WIDTH,
    height: 290,
    scale: 1,
    rotationInDegrees: 0,
  },
  style: {
    type: 'StylesInBreakpoint',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
    stylesInBreakpoints: [
      {
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
        type: 'ComponentStyle',
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-brd': '1',
            bg: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brw: '0',
            rd: '0px',
            shd: '0px 0px 0px 0px rgba(0,0,0,0)',
          },
          propertiesSource: {
            bg: 'theme',
            brd: 'theme',
            brw: 'value',
            rd: 'value',
            shd: 'value',
          },
          groups: {},
        },
      },
    ],
  },
};

export { SOSP_COMP_RESPONSIVE_COMP_DEF };
