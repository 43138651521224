import { DynamicPageLink, EditorSDK, MenuItem } from '@wix/platform-editor-sdk';
import _ from 'lodash';
import { logError } from '../utils/monitoring';
import * as menus from './wrappers/menus';
import * as pages from './wrappers/pages';
import * as pagesService from './services/pages';
import * as routers from './wrappers/routers';
import * as constants from './constants';
import { APP_TOKEN } from './constants';

type MenuItemWithOrder = MenuItem & { order?: string };

async function createMenuItem({
  editorSDK,
  menuId,
  linkData,
}: {
  editorSDK: EditorSDK;
  menuId: string;
  linkData: Partial<MenuItem>;
}) {
  const appRouters = await routers.getAll(editorSDK);
  const menuItems: MenuItemWithOrder[] = await menus.getMenuItems({ editorSDK, menuId });
  const newMenuItem = menus.createNewMenuItem(linkData);
  menuItems.push(newMenuItem);
  appRouters.forEach((router) => {
    return (
      router.config &&
      router.config.patterns &&
      Object.keys(router.config.patterns).forEach((pattern) => {
        const menuItem = menuItems
          .filter(
            (item) =>
              (item.link as DynamicPageLink)?.routerId === router.id &&
              `/${(item.link as DynamicPageLink)?.innerRoute}` === pattern,
          )
          .pop();
        if (menuItem) {
          menuItem.order = router.config.patterns[pattern].appData.menuOrder || constants.DEFAULT_MENU_ORDER;
        }
      })
    );
  });

  const reorderedItems = _.sortBy(menuItems, (item) => item.order || constants.DEFAULT_MENU_ORDER);
  return menus.updateMenuItems({ editorSDK, menuId, items: reorderedItems });
}

async function navigateToFirstPrivatePage(editorSDK: EditorSDK) {
  const allRouters = await routers.getAll(editorSDK);
  const privateRouter = allRouters.find((router) => router.config.type === 'private');
  const privatePage = privateRouter?.pages[0];

  if (!privatePage || !privatePage.pageRef) {
    const error = new Error('Could not retrieve a private page when navigating to first private page');
    logError(error, { extra: { allRouters: JSON.stringify(allRouters) } });
    throw error;
  }

  await pages.navigateToPageRef({ editorSDK, pageRef: privatePage.pageRef });
}

async function isInMembersAreaSubPage(editorSDK: EditorSDK) {
  const currentPageRef = await editorSDK.pages.getCurrent(APP_TOKEN);
  const allMAPagesRefs = await pagesService.getAllMembersPagesRefs({ editorSDK });
  return !!allMAPagesRefs.find((pageRef) => pageRef.id === currentPageRef.id);
}

async function navigateToHomePage(editorSDK: EditorSDK) {
  const pageRef = await pages.getHomePage({ editorSDK });
  return pages.navigateToPageRef({ editorSDK, pageRef });
}

export { navigateToFirstPrivatePage, createMenuItem, isInMembersAreaSubPage, navigateToHomePage };
