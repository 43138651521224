import i18next, { i18n } from 'i18next';
import i18nextHttpBackend from 'i18next-http-backend';

export default async function (baseUrl, locale): Promise<i18n> {
  return new Promise((resolve, reject) => {
    i18next.use(i18nextHttpBackend).init(
      {
        lng: locale,
        fallbackLng: 'en',
        keySeparator: '$',
        interpolation: {
          escapeValue: false,
        },
        backend: {
          loadPath: `${baseUrl}assets/locale/Members/Members_{{lng}}.json`,
          crossDomain: true,
        },
        react: {
          wait: true,
        },
      },
      (err) => (err ? reject(err) : resolve(i18next)),
    );
  });
}
