import { EditorSDK } from '@wix/platform-editor-sdk';

import { shouldUseMembersAreaOnBlocksInstallFlow } from '../../utils/experiments';
import { createPrivateAPI as createCurrentMAPrivateAPI } from './current-ma';
import { createPrivateAPI as createMAOnMSBPrivateAPI } from './ma-on-msb';
import { MembersAreaPrivateApi } from './types';

const initPrivateAPI = async (editorSDK: EditorSDK) => {
  const isMembersAreaOnMSBEnabled = await shouldUseMembersAreaOnBlocksInstallFlow();
  const publicAPIFactory = isMembersAreaOnMSBEnabled ? createMAOnMSBPrivateAPI : createCurrentMAPrivateAPI;
  return publicAPIFactory(editorSDK);
};

export const createPrivateAPI = (editorSDK: EditorSDK): MembersAreaPrivateApi => {
  const getPrivateAPI = (() => {
    let _privateAPI: MembersAreaPrivateApi;
    return async () => {
      if (_privateAPI) {
        return _privateAPI;
      }
      _privateAPI = await initPrivateAPI(editorSDK);
      return _privateAPI;
    };
  })();

  return {
    uninstall: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.uninstall();
    },
    getAllRouters: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.getAllRouters();
    },
    getRouterDataByPage: async (pageRef) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.getRouterDataByPage(pageRef);
    },
    updatePageDataInRouter: async (pageRef, dataFieldToUpdate, updatedData) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.updatePageDataInRouter(pageRef, dataFieldToUpdate, updatedData);
    },
    saveInnerRoute: async (newInnerRoute, pageRef, pageRouterData) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.saveInnerRoute(newInnerRoute, pageRef, pageRouterData);
    },
    updatePageData: async (options) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.updatePageData(options);
    },
    savePasswordPermissions: async (pageRef, privacy, plainPassword) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.savePasswordPermissions(pageRef, privacy, plainPassword);
    },
    hasSocialPages: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.hasSocialPages();
    },
    renamePage: async (pageName, pageRef, pageRouterData) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.renamePage(pageName, pageRef, pageRouterData);
    },
    removePage: async (pageData) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.removePage(pageData);
    },
  };
};
