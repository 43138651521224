const DEFAULT_APP_RESPONSIVE_LAYOUT = {
  type: 'LayoutData',
  containerLayouts: [],
  itemLayouts: [
    {
      alignSelf: 'start',
      justifySelf: 'center',
      type: 'GridItemLayout',
      gridArea: {
        rowStart: 1,
        rowEnd: 2,
        columnStart: 1,
        columnEnd: 2,
      },
      margins: {
        left: {
          value: 0,
          type: 'px',
        },
        right: {
          type: 'px',
          value: 0,
        },
      },
    },
    {
      type: 'GridItemLayout',
      gridArea: {
        rowStart: 1,
        rowEnd: 2,
        columnStart: 1,
        columnEnd: 2,
      },
      alignSelf: 'start',
      justifySelf: 'center',
      margins: {
        left: {
          type: 'px',
          value: 0,
        },
        right: {
          type: 'px',
          value: 0,
        },
      },
    },
    {
      type: 'GridItemLayout',
      gridArea: {
        rowStart: 1,
        rowEnd: 2,
        columnStart: 1,
        columnEnd: 2,
      },
      alignSelf: 'start',
      justifySelf: 'center',
      margins: {
        left: {
          type: 'px',
          value: 0,
        },
        right: {
          type: 'px',
          value: 0,
        },
      },
    },
  ],
  componentLayouts: [
    {
      height: {
        type: 'auto',
      },
      width: {
        type: 'percentage',
        value: 100,
      },
      type: 'ComponentLayout',
      maxWidth: {
        type: 'px',
        value: 1000,
      },
    },
    {
      type: 'ComponentLayout',
      hidden: false,
    },
    {
      type: 'ComponentLayout',
      hidden: false,
    },
  ],
};

export { DEFAULT_APP_RESPONSIVE_LAYOUT };
