import _ from 'lodash';
import { DynamicPageLink, EditorSDK, MenuItem } from '@wix/platform-editor-sdk';
import { APP_TOKEN, MENU_IDS } from '../constants';
import { DeepPartial } from '../../types/utility';

const BASIC_MENU_ITEM = { type: 'BasicMenuItem', items: [], isVisible: true, isVisibleMobile: true };
const { SUB_MENU_ID, LOGIN_MENU_ID, LOGIN_ICONS_MENU_ID } = MENU_IDS;

function createLoginMenu(editorSDK: EditorSDK) {
  return editorSDK.menu.create(APP_TOKEN, {
    menuData: { name: 'Login Menu', items: [] },
    customId: LOGIN_MENU_ID,
  });
}

function createLoginIconsMenu(editorSDK: EditorSDK) {
  return editorSDK.menu.create(APP_TOKEN, {
    menuData: { name: 'Login Icons', items: [] },
    customId: LOGIN_ICONS_MENU_ID,
  });
}

async function create(editorSDK: EditorSDK) {
  await removeMenus(editorSDK);
  const membersMenuIdPromise = editorSDK.menu.create(APP_TOKEN, {
    menuData: { name: 'Member Menu', items: [] },
    customId: SUB_MENU_ID,
  });
  const loginMenuIdPromise = createLoginMenu(editorSDK);
  const loginIconsMenuIdPromise = createLoginIconsMenu(editorSDK);

  const [members, login, icons] = await Promise.all([
    membersMenuIdPromise.catch(() => SUB_MENU_ID),
    loginMenuIdPromise.catch(() => LOGIN_MENU_ID),
    loginIconsMenuIdPromise.catch(() => LOGIN_ICONS_MENU_ID),
  ]);

  return { members, login, icons };
}

async function removeMenuItems(editorSDK: EditorSDK, menuId: string) {
  const menuData = await editorSDK.menu.getById(APP_TOKEN, { menuId });
  menuData.items = [];
  await editorSDK.menu.update(APP_TOKEN, { menuId, menuData });
}

async function removeMenuItem(editorSDK: EditorSDK, menuId: string, innerRoute: string) {
  const menuData = await editorSDK.menu.getById(APP_TOKEN, { menuId });
  menuData.items = menuData.items.filter((item) => (item.link as DynamicPageLink)?.innerRoute !== innerRoute);
  return editorSDK.menu.update(APP_TOKEN, { menuId, menuData });
}

async function removeMenus(editorSDK: EditorSDK) {
  const [subMenuExists, logInMenuExists, logInIconsMenuExists] = await Promise.all([
    editorSDK.menu.getById(APP_TOKEN, { menuId: SUB_MENU_ID }),
    editorSDK.menu.getById(APP_TOKEN, { menuId: LOGIN_MENU_ID }),
    editorSDK.menu.getById(APP_TOKEN, { menuId: LOGIN_ICONS_MENU_ID }),
  ]);

  const menuItemsPromises = [
    subMenuExists && removeMenuItems(editorSDK, SUB_MENU_ID),
    logInMenuExists && removeMenuItems(editorSDK, LOGIN_MENU_ID),
    logInIconsMenuExists && removeMenuItems(editorSDK, LOGIN_ICONS_MENU_ID),
  ].filter((p) => !!p);

  await Promise.all(menuItemsPromises);

  // Menus must be removed after finishing the delete of the menu items
  const menusPromises = [
    subMenuExists && editorSDK.menu.remove(APP_TOKEN, { menuId: SUB_MENU_ID }),
    logInMenuExists && editorSDK.menu.remove(APP_TOKEN, { menuId: LOGIN_MENU_ID }),
    logInIconsMenuExists && editorSDK.menu.remove(APP_TOKEN, { menuId: LOGIN_ICONS_MENU_ID }),
  ].filter((p) => !!p);

  await Promise.all(menusPromises);
}

function createNewMenuItem(linkData: Partial<MenuItem>) {
  return _.assign({}, BASIC_MENU_ITEM, linkData) as MenuItem;
}

function getMenuIds() {
  return {
    members: SUB_MENU_ID,
    login: LOGIN_MENU_ID,
    icons: LOGIN_ICONS_MENU_ID,
  };
}

async function updateMenuItemInAllMenus({
  editorSDK,
  pageRouterData,
  updatedData,
}: {
  editorSDK: EditorSDK;
  pageRouterData: { innerRoute: string };
  updatedData: DeepPartial<MenuItem>;
}) {
  const menuIds = getMenuIds();
  const updateMembersMenu = updateMenuItem({ editorSDK, pageRouterData, updatedData, menuId: menuIds.members });
  const updateLoginMenu = updateMenuItem({ editorSDK, pageRouterData, updatedData, menuId: menuIds.login });
  const updateIconsMenu = updateMenuItem({ editorSDK, pageRouterData, updatedData, menuId: menuIds.icons });

  return Promise.all([updateMembersMenu, updateLoginMenu, updateIconsMenu]);
}

async function updateMenuItem({
  editorSDK,
  pageRouterData,
  updatedData,
  menuId,
}: {
  editorSDK: EditorSDK;
  menuId: string;
  updatedData: DeepPartial<MenuItem>;
  pageRouterData: { innerRoute: string };
}) {
  const innerRoute = pageRouterData.innerRoute;
  const menuData = await editorSDK.menu.getById(APP_TOKEN, { menuId });
  const menuItem = menuData.items.find((item) => '/' + (item.link as DynamicPageLink)?.innerRoute === innerRoute);

  if (menuItem) {
    _.merge(menuItem, updatedData);
    await editorSDK.menu.update(APP_TOKEN, { menuId, menuData });
  }
}

async function getMenuItemByRoute({
  editorSDK,
  menuId,
  innerRoute,
}: {
  editorSDK: EditorSDK;
  menuId: string;
  innerRoute: string;
}) {
  const menuData = await editorSDK.menu.getById(APP_TOKEN, { menuId });
  const menuItem = menuData?.items?.find((item) => '/' + (item.link as DynamicPageLink)?.innerRoute === innerRoute);
  return menuItem;
}

async function getMenuItems({ editorSDK, menuId }: { editorSDK: EditorSDK; menuId: string }) {
  const menu = await editorSDK.menu.getById(APP_TOKEN, { menuId });

  if (!menu) {
    throw new Error('Could not retrieve the menu ' + menuId);
  }

  return menu.items;
}

function getMenuById({ editorSDK, menuId }: { editorSDK: EditorSDK; menuId: string }) {
  return editorSDK.menu.getById(APP_TOKEN, { menuId });
}

async function removePatternFromMenu({
  editorSDK,
  menuId,
  pattern,
}: {
  editorSDK: EditorSDK;
  menuId: string;
  pattern: string;
}) {
  const menuData = await editorSDK.menu.getById(APP_TOKEN, { menuId });
  const newMenuItems: MenuItem[] = [];
  for (const item of menuData.items) {
    if ((item.link as DynamicPageLink)?.innerRoute !== pattern) {
      newMenuItems.push(item);
    }
  }
  menuData.items = newMenuItems;
  await editorSDK.menu.update(APP_TOKEN, { menuId, menuData });
}

async function removePatternFromAllMenus({ editorSDK, pattern }: { editorSDK: EditorSDK; pattern: string }) {
  const menuIds = getMenuIds();
  for (const menuId of Object.values(menuIds)) {
    await removePatternFromMenu({ editorSDK, menuId, pattern });
  }
}

async function addMenuItems({ editorSDK, menuId, items }: { editorSDK: EditorSDK; menuId: string; items: MenuItem[] }) {
  const menuData = await editorSDK.menu.getById(APP_TOKEN, { menuId });
  const newMenuItems = [...(menuData?.items || []), ...items];
  return updateMenuItems({ editorSDK, menuId, items: newMenuItems });
}

async function updateMenuItems({
  editorSDK,
  menuId,
  items,
}: {
  editorSDK: EditorSDK;
  menuId: string;
  items: MenuItem[];
}) {
  const menuData = await editorSDK.menu.getById(APP_TOKEN, { menuId });
  menuData.items = items;
  await editorSDK.menu.update(APP_TOKEN, { menuId, menuData });
}

function getDefaultMenuId({ editorSDK }: { editorSDK: EditorSDK }) {
  return editorSDK.document.menu.getDefaultMenuId(APP_TOKEN);
}

export {
  create,
  createLoginMenu,
  createLoginIconsMenu,
  createNewMenuItem,
  getMenuById,
  getMenuIds,
  getMenuItemByRoute,
  getMenuItems,
  removeMenus,
  removeMenuItem,
  removePatternFromAllMenus,
  updateMenuItem,
  updateMenuItemInAllMenus,
  updateMenuItems,
  getDefaultMenuId,
  addMenuItems,
};
