import { EditorSDK } from '@wix/platform-editor-sdk';

import * as applicationState from '../../applicationState';
import { log } from '../../../utils/monitoring';
import * as pages from '../../pages';
import * as componentsWrapper from '../../wrappers/components';
import * as pagesGroupWrapper from '../../wrappers/pagesGroup';
import * as routersWrapper from '../../wrappers/routers';
import * as controllersWrapper from '../../wrappers/controllers';
import * as menusWrapper from '../../wrappers/menus';

export async function removeBrokenInstallation(editorSDK: EditorSDK, shouldLog = true, forceDelete = false) {
  if (!forceDelete) {
    return;
  }

  if (shouldLog) {
    const state = await applicationState.getApplicationComponents(editorSDK);
    const isEmpty = await applicationState.isEmpty(editorSDK);
    const extra = { state: JSON.stringify(state) };

    if (isEmpty) {
      log('Removing components for empty installation', { extra });
    } else {
      log('Removing components for broken installation', { extra });
    }
  }

  try {
    await pages.navigateToFirstPrivatePage(editorSDK);
    // eslint-disable-next-line no-empty
  } catch (e) {}

  await componentsWrapper.removeSospContainer(editorSDK);
  await pagesGroupWrapper.remove(editorSDK);

  try {
    if (await pages.isInMembersAreaSubPage(editorSDK)) {
      await pages.navigateToHomePage(editorSDK);
    }
  } catch (e) {
    log('Failed to check if MA is in the home page, continuing broken MA uninstallation', { extra: e });
  }

  await routersWrapper.removeConnectedPages(editorSDK);
  await routersWrapper.removeAllRouters(editorSDK);
  await controllersWrapper.wipeOut(editorSDK);
  await menusWrapper.removeMenus(editorSDK);
}
