import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { APP_TOKEN } from '../constants';

async function create(editorSDK: EditorSDK, groupName: string) {
  const memberPagesGroup = await editorSDK.pagesGroup.getByGroupName(APP_TOKEN, { groupName });
  if (memberPagesGroup) {
    await editorSDK.pagesGroup.remove(APP_TOKEN, { pagesGroupPointer: memberPagesGroup });
  }
  await editorSDK.pagesGroup.create(APP_TOKEN, { groupName });
}

async function addComponentToGroup(editorSDK: EditorSDK, groupName: string, componentPointer: ComponentRef) {
  const pagesGroupPointer = await editorSDK.pagesGroup.getByGroupName(APP_TOKEN, { groupName });
  // @ts-expect-error TODO: fix type in editor sdk types - componentRef should be optional
  await editorSDK.components.modes.showComponentOnlyOnPagesGroup(APP_TOKEN, { componentPointer, pagesGroupPointer });
}

async function addPageToGroup(editorSDK: EditorSDK, groupName: string, pageId: string) {
  const pagesGroupPointer = await editorSDK.pagesGroup.getByGroupName(APP_TOKEN, { groupName });
  await editorSDK.pagesGroup.addPageToPagesGroup(APP_TOKEN, { pagesGroupPointer, pageId });
}

async function remove(editorSDK: EditorSDK) {
  const pagesGroupsRefs = await editorSDK.pagesGroup.getAll(APP_TOKEN);
  for (const pageGroupRef of pagesGroupsRefs) {
    await editorSDK.pagesGroup.remove(APP_TOKEN, { pagesGroupPointer: pageGroupRef });
  }
}

export { addComponentToGroup, addPageToGroup, create, remove };
