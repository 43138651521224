import { EditorSDK } from '@wix/platform-editor-sdk';

import enforceSequentiality from '../../enforceSequentiality';
import { getIsResponsiveEditor } from '../../services/applicationState';
import { getMemberPrivacySettings } from '../../services/members-privacy-settings';
import { openGeneralSettingsPanel } from '../../wrappers/panels';
import { IntegrationApplicationWithWidgetId, MembersAreaPublicApi } from '../types';
import { getDefinitionsWithWidgetId } from './services/widget-plugins';
import { navigateToMembersAreaPage } from './services/navigation';
import { getProfileType, setProfileType } from './services/blog-writer';
import { getGeneralSettingsPanelData, modifyPages, modifyPagesAndOpenManagePages } from './services/general-settings';
import {
  getRegisteredApps,
  registerMembersAreaApps,
  installRegisteredApps,
  installApps,
  deleteVerticalApps,
  getDefinitions,
} from './integration';

export const createPublicAPI = (editorSDK: EditorSDK): MembersAreaPublicApi => {
  return {
    getRegisteredApps: () => {
      return getRegisteredApps(editorSDK);
    },
    registerMembersAreaApps: (applications, verticalAppDefId, applicationsOptions) => {
      const action = () => registerMembersAreaApps(editorSDK, applications, verticalAppDefId, applicationsOptions);
      return enforceSequentiality('editorAPI.registerMembersAreaApps', action);
    },
    installRegisteredApps: (verticalAppDefId) => {
      const action = () => installRegisteredApps(editorSDK, verticalAppDefId);
      return enforceSequentiality('editorAPI.installRegisteredApps', action);
    },
    addApplications: async (applications, shouldNavigate, options) => {
      const action = async () => {
        const _applications = applications as IntegrationApplicationWithWidgetId[];
        const applicationsHasWidgetId = _applications.some(({ widgetId }) => widgetId);
        if (applicationsHasWidgetId) {
          return installApps(editorSDK, _applications);
        }

        const definitions = await getDefinitions(editorSDK, applications);
        return installApps(editorSDK, getDefinitionsWithWidgetId(definitions));
      };

      return enforceSequentiality('editorAPI.addApplications', action);
    },
    handleVerticalDeletion: (verticalAppDefId) => {
      const action = async () => {
        await navigateToMembersAreaPage(editorSDK);
        return deleteVerticalApps(editorSDK, verticalAppDefId);
      };
      return enforceSequentiality('editorAPI.handleVerticalDeletion', action);
    },
    getProfileType: () => getProfileType(editorSDK),
    setProfileType: (profileType) => setProfileType(editorSDK, profileType),
    openGeneralSettingsPanel: async (referralInfo) => {
      await navigateToMembersAreaPage(editorSDK);
      return openGeneralSettingsPanel({
        editorSDK,
        eventPayload: { pageRef: undefined, referralInfo },
      });
    },
    getGeneralSettingsPanelData: () => {
      return getGeneralSettingsPanelData(editorSDK);
    },
    getMemberPrivacySettings: () => {
      return getMemberPrivacySettings(editorSDK);
    },
    modifyPages: (options) => {
      return modifyPages(editorSDK, options);
    },
    modifyPagesAndOpenManagePages: (options, referralInfo) => {
      return modifyPagesAndOpenManagePages(editorSDK, options, referralInfo);
    },
    getMembersPageRef: (page) => {
      throw new Error('[MAV2] PublicAPI.getMembersPageRef is not implemented');
    },
    removeMembersAreaPage: (pageId, appDefinitionId) => {
      throw new Error('[MAV2] PublicAPI.removeMembersAreaPage is not implemented');
    },
    setHorizontalLayout: (pwHeight) => {
      throw new Error('[MAV2] PublicAPI.setHorizontalLayout is not implemented');
    },
    setSidebarLayout: () => {
      throw new Error('[MAV2] PublicAPI.setSidebarLayout is not implemented');
    },
    addCustomPage: (isPrivate) => {
      throw new Error('[MAV2] PublicAPI.addCustomPage is not implemented');
    },
    refreshPageState: () => {
      return editorSDK.application.reloadManifest();
    },
    refreshMembersAreaApps: () => {
      throw new Error('[MAV2] PublicAPI.refreshMembersAreaApps is not implemented');
    },
    setProfileWidgetHeight: (height: number) => {
      throw new Error('[MAV2] PublicAPI.setProfileWidgetHeight is not implemented');
    },
    _getIsResponsiveEditor: () => {
      return Promise.resolve(getIsResponsiveEditor());
    },
    /** @deprecated */
    refreshRouters: () => {
      throw new Error('[MAV2] PublicAPI.refreshRouters is not implemented');
    },
    /** @deprecated */
    registerAdditionalWidgets: () => {},
    /** @deprecated */
    getAdditionalWidgets: () => {},
    /** @deprecated */
    installAdditionalWidgets: () => {},
  };
};
