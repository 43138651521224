import {
  MEMBERS_MENU_APP_WIDGET_PROPS,
  MENU_APP_WIDGET_CONNECTIONS,
  MENU_COMP_DEF_HORIZONTAL,
  MENU_COMP_DEF_HORIZONTAL_RTL,
} from './common-menu-layouts';

const MENU_APP_WIDGET_RESPONSIVE_LAYOUT = {
  type: 'LayoutData',
  componentLayouts: [
    {
      type: 'ComponentLayout',
      height: { type: 'px', value: 40 },
      width: { type: 'percentage', value: 100 },
      minHeight: { type: 'px', value: 40 },
      maxWidth: { type: 'px', value: 974 },
    },
  ],
  itemLayouts: [
    {
      type: 'GridItemLayout',
      gridArea: {
        rowStart: 2,
        columnStart: 1,
        rowEnd: 3,
        columnEnd: 2,
      },
      justifySelf: 'center',
      alignSelf: 'start',
      margins: {
        left: { type: 'px', value: 0 },
        top: { type: 'px', value: 0 },
        right: { type: 'px', value: 0 },
        bottom: { type: 'px', value: 0 },
      },
    },
  ],
  containerLayouts: [
    {
      type: 'GridContainerLayout',
      rows: [{ type: 'fr', value: 1 }],
      columns: [{ type: 'fr', value: 1 }],
    },
  ],
};

const MENU_COMP_RESPONSIVE_LAYOUT = {
  type: 'LayoutData',
  componentLayouts: [
    {
      type: 'ComponentLayout',
      height: { type: 'px', value: 40 },
      minHeight: { type: 'px', value: 40 },
    },
  ],
  itemLayouts: [
    {
      type: 'GridItemLayout',
      gridArea: {
        rowStart: 1,
        columnStart: 1,
        rowEnd: 2,
        columnEnd: 2,
      },
      justifySelf: 'stretch',
      alignSelf: 'start',
      margins: {
        left: { type: 'px', value: 0 },
        top: { type: 'px', value: 0 },
        right: { type: 'px', value: 0 },
        bottom: { type: 'px', value: 0 },
      },
    },
  ],
  containerLayouts: [],
};

const MENU_COMP_RESPONSIVE_STYLE = {
  type: 'StylesInBreakpoint',
  skin: 'wysiwyg.common.components.dropdownmenu.viewer.skins.OverlineMenuButtonSkin',
  stylesInBreakpoints: [
    {
      styleType: 'custom',
      type: 'ComponentStyle',
      skin: 'wysiwyg.common.components.dropdownmenu.viewer.skins.OverlineMenuButtonSkin',
      componentClassName: 'wysiwyg.viewer.components.menus.DropDownMenu',
      style: {
        // Theme or direct values
        properties: {
          'boxShadowToggleOn-shd': 'true',
          'alpha-bgDrop': '1',
          'alpha-brdh': '1',
          'alpha-brd': '0',
          'alpha-brds': '1',
          bgDrop: 'color_11',
          brd: 'color_15',
          brdh: 'color_17',
          brds: 'color_18',
          fnt: 'font_8',
          menuTotalBordersX: '0',
          pad: '10',
          rd: '0px',
          shd: '0px 1px 4px 0px rgba(0,0,0,0.2)',
          txt: 'color_15',
          txth: 'color_17',
          txts: 'color_18',
        },
        // Specifies whether value is taken from theme or directly
        propertiesSource: {
          'boxShadowToggleOn-shd': 'value',
          'alpha-bgDrop': 'value',
          'alpha-brd': 'value',
          'alpha-brdh': 'value',
          'alpha-brds': 'value',
          bgDrop: 'theme',
          brd: 'theme',
          brdh: 'theme',
          brds: 'theme',
          fnt: 'theme',
          menuTotalBordersX: 'value',
          pad: 'value',
          rd: 'value',
          shd: 'value',
          txt: 'theme',
          txth: 'theme',
          txts: 'theme',
        },
      },
    },
  ],
};

const MEMBERS_MENU_RESPONSIVE_APP_WIDGET = {
  ...MEMBERS_MENU_APP_WIDGET_PROPS,
  layoutResponsive: MENU_APP_WIDGET_RESPONSIVE_LAYOUT,
  components: [
    {
      ...MENU_COMP_DEF_HORIZONTAL,
      layoutResponsive: MENU_COMP_RESPONSIVE_LAYOUT,
      style: MENU_COMP_RESPONSIVE_STYLE,
      connections: MENU_APP_WIDGET_CONNECTIONS,
    },
  ],
};

const MEMBERS_MENU_RESPONSIVE_APP_WIDGET_RTL = {
  ...MEMBERS_MENU_APP_WIDGET_PROPS,
  layoutResponsive: MENU_APP_WIDGET_RESPONSIVE_LAYOUT,
  components: [
    {
      ...MENU_COMP_DEF_HORIZONTAL_RTL,
      layoutResponsive: MENU_COMP_RESPONSIVE_LAYOUT,
      style: MENU_COMP_RESPONSIVE_STYLE,
      connections: MENU_APP_WIDGET_CONNECTIONS,
    },
  ],
};

export { MEMBERS_MENU_RESPONSIVE_APP_WIDGET, MEMBERS_MENU_RESPONSIVE_APP_WIDGET_RTL };
