import { EditorSDK } from '@wix/platform-editor-sdk';
import { IntegrationApplication, MembersAreaAppId } from '@wix/members-area-app-definitions';
import { getAppDefinitions } from '@wix/members-area-app-definitions/dist/esm/getAppDefinition';
import { createInstance } from 'i18next';

import { IntegrationApplicationWithWidgetId } from '../../types';

export const groupDefinitionsByMethod = (definitions: IntegrationApplicationWithWidgetId[]) => {
  const definitionsGroupedByMethod = {
    widgetPlugins: [] as IntegrationApplicationWithWidgetId[],
    siteApps: [] as IntegrationApplication[],
  };

  definitions.forEach((definition) => {
    if (definition.method === 'addApplicationToSite') {
      definitionsGroupedByMethod.siteApps.push(definition);
    } else {
      definitionsGroupedByMethod.widgetPlugins.push(definition);
    }
  });

  return definitionsGroupedByMethod;
};

export const getDefinitions = async (editorSDK: EditorSDK, membersAreaAppIds: MembersAreaAppId[]) => {
  const definitions = await getAppDefinitions({
    editorSDK,
    applications: membersAreaAppIds,
    i18next: createInstance(),
  });

  return definitions;
};
