import { EditorSDK } from '@wix/platform-editor-sdk';
import i18nInitializer from './i18nInitializer';
import { getSiteLocale, getEditorLocale } from '../utils/locale';
import * as appState from '../editor-app-module/services/applicationState';

export default i18nInitializer;

export const getTranslationFunction = async (editorSDK: EditorSDK, useEditorLocale?: boolean) => {
  const locale = useEditorLocale ? await getEditorLocale(editorSDK) : await getSiteLocale(editorSDK);
  const i18n = await i18nInitializer(appState.getStaticsBaseUrl(), locale);
  return i18n.t.bind(i18n);
};
