import { EditorSDK } from '@wix/platform-editor-sdk';
import { MA_APP_IDS } from '@wix/members-area-app-definitions';

import { APP_TOKEN, PROFILE_WIDGET_APP } from '../constants';
import { isApplicationInstalled } from '../wrappers/tpa';
import { getHttpClient } from '../../utils/httpClient';

type MembersAreaAppApi = {
  refreshApp?(): Promise<void>;
};

type BooleanSettingsAPI = {
  setBooleanStyleParamForWidgets(key: string, value: boolean): Promise<void>;
};

const MEMBERS_AREA_API_URL = 'https://members.wixapps.net/_api/members-area/_api';

export const publishSettingsForMembersAreaApps = async (editorSDK: EditorSDK) => {
  const httpClient = await getHttpClient(editorSDK, MEMBERS_AREA_API_URL);
  await httpClient.post('/settings/publish', {});
};

export const mergeGlobalSettings = async (editorSDK: EditorSDK, settings: Record<string, boolean>) => {
  const httpClient = await getHttpClient(editorSDK, MEMBERS_AREA_API_URL);
  await httpClient.put('/settings/merge/global?viewMode=Editor', settings);
};

export const refreshMembersAreaApps = async (editorSDK: EditorSDK) => {
  const appsToRefresh = [MA_APP_IDS.ABOUT, MA_APP_IDS.FOLLOWERS, MA_APP_IDS.ALL_MEMBERS, PROFILE_WIDGET_APP];

  const appRefreshPromises = appsToRefresh.map(async ({ appDefinitionId }) => {
    const api: MembersAreaAppApi | void = await editorSDK.application.getPublicAPI(APP_TOKEN, { appDefinitionId });

    return api?.refreshApp?.();
  });

  await Promise.all(appRefreshPromises);
};

export const setProfileCardBooleanParam = async (editorSDK: EditorSDK, param: { key: string; value: boolean }) => {
  const isPWInstalled = await isApplicationInstalled({
    editorSDK,
    appDefinitionId: PROFILE_WIDGET_APP.appDefinitionId,
  });
  if (!isPWInstalled) {
    return;
  }
  const api = (await editorSDK.application.getPublicAPI(APP_TOKEN, {
    appDefinitionId: PROFILE_WIDGET_APP.appDefinitionId,
  })) as BooleanSettingsAPI | undefined;

  await api?.setBooleanStyleParamForWidgets(param.key, param.value);
};
