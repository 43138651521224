import { EditorSDK } from '@wix/platform-editor-sdk';
import { getMemberPrivacySettings as fetchMemberPrivacySettings } from '@wix/ambassador-members-v1-member-privacy-settings/http';

import { getHttpClient } from '../../utils/httpClient';

export const getMemberPrivacySettings = async (editorSDK: EditorSDK) => {
  const httpClient = await getHttpClient(editorSDK);
  const { data } = await httpClient.request(fetchMemberPrivacySettings({}));
  return data.memberPrivacySettings;
};
