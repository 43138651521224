import { EditorSDK } from '@wix/platform-editor-sdk';
import { IntegrationApplication } from '@wix/members-area-integration-kit';

import { retryPromise } from '../../../../utils/promises';
import { getById as getComponentById, getComponentPage } from '../../../wrappers/components';
import { remove as removePage } from '../../../wrappers/pages';
import { getPageByIntegrationApp, updatePageWithManagingAppDefId } from '../../../services/pages';
import { IntegrationApplicationWithWidgetId } from '../../types';
import { removeApplicationsFromSlots } from '../services/members-area-page';
import { groupDefinitionsByMethod } from './definitions';
import { registerAlwaysAvailableApps } from './register-apps';
import { getAppsToDeleteWithVertical } from './get-apps';

const deleteSitePage = async (editorSDK: EditorSDK, definition: IntegrationApplication) => {
  const page = await getPageByIntegrationApp({ editorSDK, app: definition });
  if (!page) {
    return;
  }

  const componentRef = await getComponentById({ editorSDK, id: page.id! });
  if (!componentRef) {
    return;
  }

  const pageRef = await getComponentPage(editorSDK, componentRef);
  if (!pageRef) {
    return;
  }

  if (!page.managingAppDefId) {
    await updatePageWithManagingAppDefId({ editorSDK, pageRef });
  }

  const retryOptions = { delayMs: 100, message: `Failed to delete site level app ${definition.appDefinitionId}` };
  return retryPromise(() => removePage({ editorSDK, pageRef, shouldShowEditorRemovePanel: false }), retryOptions);
};

const deleteSitePages = async (editorSDK: EditorSDK, definitions: IntegrationApplication[]) => {
  await Promise.all(definitions.map((definition) => deleteSitePage(editorSDK, definition)));
};

export const deleteApps = async (editorSDK: EditorSDK, definitions: IntegrationApplicationWithWidgetId[]) => {
  const { widgetPlugins, siteApps } = groupDefinitionsByMethod(definitions);

  if (widgetPlugins.length > 0) {
    await removeApplicationsFromSlots(editorSDK, widgetPlugins);
  }

  if (siteApps.length > 0) {
    await deleteSitePages(editorSDK, siteApps);
  }

  await registerAlwaysAvailableApps(editorSDK);
};

export const deleteVerticalApps = async (editorSDK: EditorSDK, verticalAppDefId: string) => {
  const appsToDeleteWithVertical = await getAppsToDeleteWithVertical(editorSDK, verticalAppDefId);
  return deleteApps(editorSDK, appsToDeleteWithVertical);
};
