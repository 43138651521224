import { MembersAreaPlatformApp } from '../types';
import { editorReady } from './editor-ready';
import { getAppManifest } from './manifest';
import { onEvent } from './on-event';

export { createPublicAPI } from './public-api';
export { createPrivateAPI } from './private-api';

export const currentMembersArea: MembersAreaPlatformApp = {
  editorReady,
  getAppManifest,
  onEvent,
};
