import { APP_TOKEN } from '../editor-app-module/constants';
import { EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';

const RAVEN_INIT_STRING = 'https://d71c47c01a6e4b2a9c1cf460e3f76627@sentry.wixpress.com/27';

let fedopsInstance, sentryMonitor;

type SentryTags = {
  metaSiteId: string;
  siteRegion: string;
  siteLanguage: string;
  firstInstall?: boolean;
  type?: string;
  editorScriptUrl?: string;
  originInfo?: string;
};

async function initializeMonitoring(editorSDK: EditorSDK, options: EditorReadyOptions) {
  const [metaSiteId, siteRegion, siteLanguage] = await Promise.all([
    editorSDK.info.getMetaSiteId(APP_TOKEN),
    editorSDK.info.getSiteRegion(APP_TOKEN),
    editorSDK.info.getLanguage(APP_TOKEN),
  ]);
  const tags: SentryTags = {
    metaSiteId,
    siteRegion,
    siteLanguage,
  };

  if (typeof options.firstInstall !== 'undefined') {
    tags.firstInstall = options.firstInstall;
  }
  if (options.origin && options.origin.type) {
    tags.type = options.origin.type;
  }

  if (options.origin?.info) {
    tags.originInfo = options.origin.info?.type || options.origin.info?.appDefinitionId;
  }

  if (options.initialAppData && options.initialAppData.editorScriptUrl) {
    tags.editorScriptUrl = options.initialAppData.editorScriptUrl;
  }

  const configuration = {
    dataCallback: () => {},
    tags,
  };
  sentryMonitor = options?.monitoring?.createSentryMonitorForApp(RAVEN_INIT_STRING, configuration);

  fedopsInstance = options?.monitoring?.createFedopsLogger();

  if (!sentryMonitor || !fedopsInstance) {
    console.error(`Issues while initialising loggers. Sentry: ${sentryMonitor}, Fedops: ${fedopsInstance}`);
  }

  await editorSDK.document.errors.registerToErrors('', {
    callback: ({ error, methodName }) => {
      console.log(`An error occured in DS, method ${methodName}, error: ${error}`);
      log('Error in DS', { tags: { errorMessage: error.toString(), methodName } });
    },
  });
}

function interactionStarted(interactionName: string) {
  try {
    fedopsInstance.interactionStarted(interactionName);
  } catch (e) {
    const err = 'Failed to start fedops interaction, reason: ' + e;
    sentryMonitor?.captureException(err);
  }
}

function interactionEnded(interactionName: string) {
  try {
    fedopsInstance.interactionEnded(interactionName);
  } catch (e) {
    const err = 'Failed to end fedops interaction, reason: ' + e;
    sentryMonitor?.captureException(err);
  }
}

function interactionFailed(interactionName: string, err: Error) {
  sentryMonitor?.captureException(err, { tags: { interactionName } });
}

async function toMonitored<T>(interactionName: string, promise: () => Promise<T> | T): Promise<T> {
  try {
    interactionStarted(interactionName);
    const response = await promise();
    interactionEnded(interactionName);
    return response;
  } catch (err) {
    interactionFailed(interactionName, err as Error);
    throw err;
  }
}

function log(message: string, options: any = {}) {
  sentryMonitor?.captureMessage(message, { level: 'info', ...options });
}

function logError(err: Error, extra: any = {}) {
  sentryMonitor?.captureException(err, extra);
}

export { initializeMonitoring, interactionStarted, interactionEnded, interactionFailed, toMonitored, log, logError };
