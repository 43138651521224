import { AppManifest } from '@wix/platform-editor-sdk';
import { TranslationFunction } from 'i18next';
import { MembersAreaEvent } from '../../../types';

type PageActions = NonNullable<AppManifest['pages']>['pageActions'];

export const getPageActions = (t: TranslationFunction): PageActions => {
  return {
    default: [],
    deletable: [
      {
        title: t('Pages_Panel_MemberMenu_Actions_Page_Delete'),
        icon: 'deleteAction',
        event: MembersAreaEvent.RemovePage,
      },
    ],
  };
};
