import { EditorSDK, PageData, RouterData } from '@wix/platform-editor-sdk';
import { getAppDefinitions } from '@wix/members-area-app-definitions/dist/esm/getAppDefinition';
import { MA_APP_IDS } from '@wix/members-area-integration-kit';
import { createInstance } from 'i18next';

import { RouterPatterns } from '../../types/EditorAppModule';
import { ReferralInfo } from '../../types/bi';
import { Page, PagesModificationPayload, PageType } from '../../types/general-settings';
import { arePagesEqual } from '../../utils/pages';
import { getTranslationFunction } from '../../i18n';
import { MEMBERS_LIST_PAGE_ID, MEMBERS_LIST_APP_DEF_ID, APP_TOKEN } from '../constants';
import { maybeAddApplications } from '../platform-app/current-ma/public-api';
import { removeMembersAreaPageByPageId, removeMembersAreaPage } from '../platform-api/removeMembersAreaPage';
import { getAllPages } from '../wrappers/pages';
import { openManagePagesPanel } from '../wrappers/panels';
import { getMembersAreaRouters } from './routers';
import { removeMembersList } from './pages';
import { setProfileType } from './profile';

const getPagesFromPublicRouter = (publicRouter: RouterData): Page[] => {
  const routerPatterns = Object.values((publicRouter.config.patterns ?? {}) as unknown as RouterPatterns);

  return routerPatterns.map(({ title, pageId, appData: { appDefinitionId, appPageId }, seoData }) => ({
    pageType: appPageId ? PageType.TPA : PageType.Custom,
    integrationApplication: { appDefinitionId, pageId: appPageId ? appPageId : pageId! },
    title: seoData?.title?.replace('{userName} | ', '') ?? title,
    isInstalled: true,
  }));
};

const getMemberListPage = (pages: PageData[]): Page | null => {
  const memberListPage = pages.find(({ tpaPageId }) => tpaPageId === MEMBERS_LIST_PAGE_ID);

  if (!memberListPage) {
    return null;
  }

  return {
    pageType: PageType.StandAlone,
    integrationApplication: { appDefinitionId: MEMBERS_LIST_APP_DEF_ID, pageId: undefined as any },
    title: memberListPage.title,
    isInstalled: true,
  };
};

const getInstalledPublicPages = async (editorSDK: EditorSDK): Promise<Page[]> => {
  const [{ publicRouter }, allInstalledPages] = await Promise.all([
    getMembersAreaRouters(editorSDK),
    getAllPages({ editorSDK }),
  ]);
  const pagesInPublicRouter = getPagesFromPublicRouter(publicRouter);
  const membersListPage = getMemberListPage(allInstalledPages);

  return membersListPage ? [...pagesInPublicRouter, membersListPage] : pagesInPublicRouter;
};

const getPromotionalPublicPages = async (editorSDK: EditorSDK, publicPages: Page[]): Promise<Page[]> => {
  const t = await getTranslationFunction(editorSDK, true);
  const definitions = await getAppDefinitions({
    editorSDK,
    i18next: createInstance(),
    applications: [MA_APP_IDS.ABOUT, MA_APP_IDS.FOLLOWERS, MA_APP_IDS.ALL_MEMBERS],
  });

  return definitions.map((definition) => {
    const promotionalPage = publicPages.find(({ integrationApplication }) => {
      return arePagesEqual(integrationApplication, definition);
    });

    if (promotionalPage) {
      return { ...promotionalPage, application: definition };
    }

    const { page } = definition;
    const isMembersListPage = arePagesEqual(definition, MA_APP_IDS.ALL_MEMBERS);

    return {
      pageType: isMembersListPage ? PageType.StandAlone : PageType.TPA,
      title: isMembersListPage ? t('GeneralSettings_MembersPageTitle') : page?.name ?? '',
      integrationApplication: definition,
      isInstalled: false,
    };
  });
};

export const getGeneralSettingsPanelData = async (editorSDK: EditorSDK) => {
  const installedPublicPages = await getInstalledPublicPages(editorSDK);
  const promotionalPublicPages = await getPromotionalPublicPages(editorSDK, installedPublicPages);
  return { installedPublicPages, promotionalPublicPages };
};

export const modifyPages = async (
  editorSDK: EditorSDK,
  { appsToInstall, appsToRemove, profilePageType }: PagesModificationPayload,
) => {
  let currentStep = 0;
  const totalSteps = appsToInstall.length + appsToRemove.length + (profilePageType ? 1 : 0);
  const t = await getTranslationFunction(editorSDK, true);

  if (!totalSteps) {
    return;
  }

  await editorSDK.editor.openProgressBar(APP_TOKEN, {
    title: t('GeneralSettings_ProgressModal_Title'),
    totalSteps,
    stepTitle: t('GeneralSettings_ProgressModal_Subtitle'),
  });

  try {
    if (appsToInstall.length) {
      await maybeAddApplications(
        appsToInstall.map(({ integrationApplication }) => integrationApplication),
        false,
      );
      await editorSDK.editor.updateProgressBar(APP_TOKEN, {
        currentStep: (currentStep += appsToInstall.length),
        stepTitle: t('GeneralSettings_ProgressModal_Subtitle'),
      });
    }

    if (appsToRemove.length) {
      for (const { pageType, integrationApplication } of appsToRemove) {
        const isMembersListPage = integrationApplication.appDefinitionId === MEMBERS_LIST_APP_DEF_ID;
        const shouldDisableTransaction = true;

        if (pageType === PageType.StandAlone && isMembersListPage) {
          await removeMembersList(editorSDK, shouldDisableTransaction);
        } else if (pageType === PageType.TPA) {
          await removeMembersAreaPageByPageId({ ...integrationApplication, editorSDK, shouldDisableTransaction });
        } else if (pageType === PageType.Custom) {
          await removeMembersAreaPage({ editorSDK, id: integrationApplication.pageId, shouldDisableTransaction });
        }

        await editorSDK.editor.updateProgressBar(APP_TOKEN, {
          currentStep: ++currentStep,
          stepTitle: t('GeneralSettings_ProgressModal_Subtitle'),
        });
      }
    }

    if (profilePageType) {
      await setProfileType(editorSDK, profilePageType);
      await editorSDK.editor.updateProgressBar(APP_TOKEN, {
        currentStep: ++currentStep,
        stepTitle: t('GeneralSettings_ProgressModal_Subtitle'),
      });
    }

    await editorSDK.editor.closeProgressBar(APP_TOKEN, {
      isError: false,
    });
  } catch (e) {
    await editorSDK.editor.closeProgressBar(APP_TOKEN, {
      isError: true,
    });

    throw e;
  }
};

export const modifyPagesAndOpenManagePages = async (
  editorSDK: EditorSDK,
  options: PagesModificationPayload,
  referralInfo?: ReferralInfo,
) => {
  await modifyPages(editorSDK, options);
  openManagePagesPanel({ editorSDK, eventPayload: { pageRef: null, referralInfo } });
};
