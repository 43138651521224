import _ from 'lodash';
import { ComponentRef, EditorSDK, ResponsiveLayout } from '@wix/platform-editor-sdk';

import * as constants from '../constants';
import { getAllMembersPagesRefs } from '../services/pages';
import { getController, getAllControllers, removeConnectedComponents } from '../wrappers/controllers';
import { getPageData } from '../wrappers/pages';
import { isInMembersAreaSubPage } from '../pages';
import { log } from '../../utils/monitoring';
import { shouldDeleteMobileComponents } from '../../utils/experiments';
import { getAllCompsByApplicationId } from '../wrappers/tpa';
import {
  addSubPagesMenu,
  getSOSPContainerRef,
  getComponentLayout,
  updateComponentLayout,
  removeComponent,
  getSOSPProfileCardComponentRef,
  fixSOSPHeightForVerticalLayout,
  getById,
  getComponentChildren,
  updateFullStyle,
  updateComponentResponsiveLayout,
  getComponentResponsiveLayout,
  removeMobileComponent,
} from '../wrappers/components';
import * as layoutsService from '../services/layouts';
import { MENU_COMP_DEF_HORIZONTAL } from '../constants';
import { getIsResponsiveEditor } from '../services/applicationState';

async function verifySOSPColor({
  editorSDK,
  isHorizontal,
  sospContainerRef,
}: {
  editorSDK: EditorSDK;
  isHorizontal: boolean;
  sospContainerRef: ComponentRef;
}) {
  const sospStyle = await editorSDK.components.style.get('', { componentRef: sospContainerRef });
  const alpha = sospStyle.style.properties['alpha-bg'];
  const bgColor = sospStyle.style.properties.bg;

  if (isHorizontal && !(alpha === 1 && bgColor === 'color_11')) {
    log('The color of SOSP did not change properly after relayout when changing to horizontal layout', {
      tags: { isHorizontal },
      extra: { alpha, bgColor },
    });
  }

  if (!isHorizontal && !(alpha === 0 && bgColor === 'color_1')) {
    log('The color of SOSP did not change properly after relayout when changing to vertical layout', {
      tags: { isHorizontal },
      extra: { alpha, bgColor },
    });
  }
}

async function relayoutSOSP({
  editorSDK,
  sospContainerRef,
  pwComponentRef,
  isHorizontal,
  pwHeight,
}: {
  editorSDK: EditorSDK;
  sospContainerRef: ComponentRef;
  pwComponentRef: ComponentRef;
  isHorizontal: boolean;
  pwHeight?: number;
}) {
  const newSospHeight = pwHeight
    ? pwHeight + MENU_COMP_DEF_HORIZONTAL.layout.height
    : constants.SOSP_CONTAINER_HORIZONTAL.layout.height;

  const sospLayout = _.cloneDeep(
    isHorizontal
      ? { ...constants.SOSP_CONTAINER_HORIZONTAL.layout, height: newSospHeight }
      : constants.SOSP_CONTAINER.layout,
  );
  const sospStyle = _.cloneDeep(
    isHorizontal ? constants.SOSP_CONTAINER_HORIZONTAL.style : constants.SOSP_CONTAINER.style,
  );
  const headerRef = await editorSDK.siteSegments.getHeader('');
  const headerLayout = await getComponentLayout({ editorSDK, componentRef: headerRef });
  sospLayout.y += headerLayout.height;

  await Promise.all([
    updateComponentLayout({ editorSDK, componentRef: sospContainerRef, layout: sospLayout }),
    // @ts-expect-error - Either incorrect types in platform editor sdk or an issue when updating sosp styles
    updateFullStyle({ editorSDK, componentRef: sospContainerRef, style: sospStyle }),
  ]);

  // MA-390 investigation
  verifySOSPColor({ editorSDK, isHorizontal, sospContainerRef });

  // Workarounding layouting issues in document services.. need to trigger it multiple times to sit correctly
  setTimeout(
    () => updateComponentLayout({ editorSDK, componentRef: sospContainerRef, layout: { y: sospLayout.y } }),
    0,
  );
  setTimeout(
    () => updateComponentLayout({ editorSDK, componentRef: sospContainerRef, layout: { height: sospLayout.height } }),
    0,
  );

  // Layout vertical sidebar with proper margins
  if (!isHorizontal) {
    await fixSOSPHeightForVerticalLayout({ editorSDK, pwComponentRef, sospContainerRef });
  }
}

async function removeComponentOrController({
  editorSDK,
  allControllers,
  componentRef,
}: {
  editorSDK: EditorSDK;
  allControllers: { controllerRef: ComponentRef }[];
  componentRef: ComponentRef;
}) {
  const controller = allControllers.find((c) => c.controllerRef.id === componentRef.id);

  if (controller) {
    await removeConnectedComponents(editorSDK, controller);
  }

  return removeComponent({ editorSDK, componentRef });
}

// Deleting other than PW components (including menus) in SOSP to avoid layout issues
async function clearSOSPContainer({
  editorSDK,
  pwComponentRef,
  sospContainerRef,
}: {
  editorSDK: EditorSDK;
  pwComponentRef: ComponentRef;
  sospContainerRef: ComponentRef;
}) {
  const pwRefMobile = { ...pwComponentRef, type: 'MOBILE' };
  const sospRefMobile = { ...sospContainerRef, type: 'MOBILE' as 'MOBILE' };
  const sospChildrenRefs = await getComponentChildren({ editorSDK, componentRef: sospContainerRef });
  const sospChildrenMobileRefs = await getComponentChildren({ editorSDK, componentRef: sospRefMobile });

  const unexpectedComponentsRefs = sospChildrenRefs.filter((comp) => !_.isEqual(comp, pwComponentRef));
  const unexpectedMobileComponentsRefs = sospChildrenMobileRefs.filter((comp) => !_.isEqual(comp, pwRefMobile));

  const allControllers = await getAllControllers(editorSDK);
  const isMobileDeleteExperimentEnabled = await shouldDeleteMobileComponents();

  await Promise.all(
    unexpectedComponentsRefs.map((componentRef) =>
      removeComponentOrController({ editorSDK, allControllers, componentRef }),
    ),
  );

  if (isMobileDeleteExperimentEnabled) {
    await Promise.all(
      unexpectedMobileComponentsRefs.map((componentRef) => removeMobileComponent({ editorSDK, componentRef })),
    );
  }

  return;
}

async function addMenuToSOSP({
  editorSDK,
  isHorizontal,
  sospContainerRef,
  pwHeight,
}: {
  editorSDK: EditorSDK;
  isHorizontal: boolean;
  sospContainerRef: ComponentRef;
  pwHeight?: number;
}) {
  const controllerRef = await getController(editorSDK);
  // TODO: Possible issue - controllerRef type mismatch
  await addSubPagesMenu(
    editorSDK,
    constants.MENU_IDS.SUB_MENU_ID,
    sospContainerRef,
    controllerRef!,
    isHorizontal,
    pwHeight,
  );
}

async function relayoutPW({
  editorSDK,
  pwComponentRef,
  isHorizontal,
  pwHeight,
}: {
  editorSDK: EditorSDK;
  pwComponentRef: ComponentRef;
  isHorizontal: boolean;
  pwHeight?: number;
}) {
  const newPwHeight = pwHeight || constants.PW_HORIZONTAL_LAYOUT.height;

  const layout = isHorizontal
    ? { ...constants.PW_HORIZONTAL_LAYOUT, height: newPwHeight }
    : constants.PW_VERTICAL_LAYOUT;
  await updateComponentLayout({ editorSDK, componentRef: pwComponentRef, layout });
}

async function getAllMAPagesTPASectionsComponents({ editorSDK }: { editorSDK: EditorSDK }) {
  const allPagesRefs = await getAllMembersPagesRefs({ editorSDK });
  const allPagesDatas = await Promise.all(allPagesRefs.map((pageRef) => getPageData({ editorSDK, pageRef })));
  const allVerticalsPagesDatas = allPagesDatas.filter(
    (pageData) => pageData.tpaApplicationId && pageData.tpaApplicationId > 0,
  );
  const allCustomPagesDatas = allPagesDatas.filter(
    (pageData) => typeof pageData.tpaApplicationId === 'undefined' || pageData.tpaApplicationId === 0,
  );

  const allCustomPagesIds = allCustomPagesDatas.map((pageData) => pageData.id);
  const allApplicationsIds = allVerticalsPagesDatas.map((pageData) => pageData.tpaApplicationId);
  const [allCustomPagesCompRefs, allApplicationsComponents] = await Promise.all([
    Promise.all(allCustomPagesIds.map((id) => getById({ editorSDK, id: id! }))),
    Promise.all(
      allApplicationsIds.map((applicationId) =>
        // TODO: Possible issue - applicationId type mismatch
        getAllCompsByApplicationId({ editorSDK, applicationId: applicationId! }),
      ),
    ),
  ]);

  const allApplicationsComponentsFlat = allApplicationsComponents.reduce((acc, comps) => [...acc, ...comps], []);
  const onlyMAPagesComponents = allApplicationsComponentsFlat.filter(
    (comp) => allPagesDatas.map((data) => data.id).indexOf(comp.pageId) > -1,
  );

  const [allCustomPagesComponents, allVerticalsPagesComponentsRefs] = await Promise.all([
    Promise.all(allCustomPagesCompRefs.map((componentRef) => getComponentChildren({ editorSDK, componentRef }))),
    Promise.all(onlyMAPagesComponents.map((comp) => getById({ editorSDK, id: comp.id }))),
  ]);

  const allCustomPagesComponentsFlat = allCustomPagesComponents.reduce((acc, comps) => [...acc, ...comps], []);

  return { allVerticalsPagesComponentsRefs, allCustomPagesComponentsFlat };
}

async function maybeUpdateCustomPageComponentLayout({
  editorSDK,
  componentRef,
  isHorizontal,
  pwHeight,
}: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  isHorizontal: boolean;
  pwHeight?: number;
}) {
  const componentLayout = await getComponentLayout({ editorSDK, componentRef });

  // If components are out of main section, don't do anything with them
  if (componentLayout.x < 0 || componentLayout.x > constants.CLASSIC_EDITOR_MAIN_SECTION_WIDTH) {
    return;
  }

  // Move components left and down if layout is being changed to horizontal
  const diffX = constants.SECTION_DEFAULT_LAYOUT.x - constants.SECTION_DEFAULT_LAYOUT_HORIZONTAL.x;
  const diffY =
    constants.SECTION_DEFAULT_LAYOUT.y -
    (pwHeight
      ? pwHeight + constants.MENU_COMP_DEF_HORIZONTAL.layout.height
      : constants.SECTION_DEFAULT_LAYOUT_HORIZONTAL.y);

  const newLayout = { ...componentLayout };
  if (isHorizontal) {
    newLayout.x = componentLayout.x - diffX;
    newLayout.y = componentLayout.y - diffY;
  } else {
    newLayout.x = componentLayout.x + diffX;
    newLayout.y = componentLayout.y + diffY;
  }

  await updateComponentLayout({ editorSDK, componentRef, layout: newLayout });
}

async function relayoutMASections({
  editorSDK,
  isHorizontal,
  pwHeight,
}: {
  editorSDK: EditorSDK;
  isHorizontal: boolean;
  pwHeight?: number;
}) {
  const { allVerticalsPagesComponentsRefs, allCustomPagesComponentsFlat } = await getAllMAPagesTPASectionsComponents({
    editorSDK,
  });
  const newSectionYPosition = pwHeight
    ? pwHeight + MENU_COMP_DEF_HORIZONTAL.layout.height
    : constants.SECTION_DEFAULT_LAYOUT_HORIZONTAL.y;

  const verticalsComponentsLayout = isHorizontal
    ? { ...constants.SECTION_DEFAULT_LAYOUT_HORIZONTAL, y: newSectionYPosition }
    : constants.SECTION_DEFAULT_LAYOUT;

  const allPromises = [
    ...allVerticalsPagesComponentsRefs.map((componentRef) =>
      updateComponentLayout({ editorSDK, componentRef, layout: verticalsComponentsLayout }),
    ),
    ...allCustomPagesComponentsFlat.map((componentRef) =>
      maybeUpdateCustomPageComponentLayout({ editorSDK, componentRef, isHorizontal }),
    ),
  ];

  return Promise.all(allPromises);
}

async function relayoutMA({
  editorSDK,
  isHorizontal,
  pwHeight,
}: {
  editorSDK: EditorSDK;
  isHorizontal: boolean;
  pwHeight?: number;
}) {
  const isInMembersArea = await isInMembersAreaSubPage(editorSDK);

  if (!isInMembersArea) {
    return;
  }

  const sospContainerRef = await getSOSPContainerRef(editorSDK);
  const pwComponentRef = await getSOSPProfileCardComponentRef({ editorSDK });

  if (!pwComponentRef) {
    return;
  }

  // Not in parallel because editor struggles to handle this relayouting
  // Must layout SOSP last as otherwise it can be weirdly stretched by the components inside
  await clearSOSPContainer({ editorSDK, sospContainerRef, pwComponentRef });
  await relayoutPW({ editorSDK, pwComponentRef, isHorizontal, pwHeight });
  await addMenuToSOSP({ editorSDK, sospContainerRef, isHorizontal, pwHeight });
  // Triggering PW relayout two times as margins jumped back to 0 with only one relayout..
  await relayoutPW({ editorSDK, pwComponentRef, isHorizontal, pwHeight });
  await relayoutSOSP({ editorSDK, sospContainerRef, pwComponentRef, isHorizontal, pwHeight });
  await relayoutMASections({ editorSDK, isHorizontal, pwHeight });
}

function setHorizontalLayout(editorSDK: EditorSDK, pwHeight: number) {
  return relayoutMA({ editorSDK, isHorizontal: true, pwHeight });
}

function setSidebarLayout(editorSDK: EditorSDK) {
  return relayoutMA({ editorSDK, isHorizontal: false });
}

async function setHorizontalPWHeight(editorSDK: EditorSDK, newHeight: number) {
  const { allVerticalsPagesComponentsRefs, allCustomPagesComponentsFlat } = await getAllMAPagesTPASectionsComponents({
    editorSDK,
  });
  const isHorizontal = await layoutsService.isMyAccountLayoutHorizontal({ editorSDK });
  const isResponsiveEditor = getIsResponsiveEditor();

  if (!isHorizontal) {
    return;
  }

  const menuHeight = 40;
  const newSectionYPosition = newHeight + menuHeight;
  const newSospHeight = newSectionYPosition;

  const pwComponentRef = await getSOSPProfileCardComponentRef({ editorSDK });

  if (isResponsiveEditor) {
    const responsiveLayout = await getComponentResponsiveLayout({ editorSDK, componentRef: pwComponentRef });
    const newResponsiveLayout = {
      ...responsiveLayout,
      componentLayouts: responsiveLayout.componentLayouts.map((componentLayout) => ({
        ...componentLayout,
        height: { ...componentLayout.height, value: newHeight },
      })),
    };

    await updateComponentResponsiveLayout({
      editorSDK,
      componentRef: pwComponentRef,
      responsiveLayout: newResponsiveLayout as unknown as ResponsiveLayout,
    });
  }

  const sospContainerRef = await getSOSPContainerRef(editorSDK);
  await updateComponentLayout({ editorSDK, componentRef: sospContainerRef, layout: { height: newSospHeight } });

  const allPromises = [
    ...allVerticalsPagesComponentsRefs.map((componentRef) =>
      updateComponentLayout({ editorSDK, componentRef, layout: { y: newSectionYPosition } }),
    ),
    ...allCustomPagesComponentsFlat.map((componentRef) =>
      updateComponentLayout({ editorSDK, componentRef, layout: { y: newSectionYPosition } }),
    ),
  ];

  await Promise.all(allPromises);
}

export { setHorizontalLayout, setSidebarLayout, setHorizontalPWHeight };
