import { CustomMenuData, EditorSDK } from '@wix/platform-editor-sdk';
import { APP_TOKEN } from './constants';
import * as menus from './wrappers/menus';
import * as routers from './wrappers/routers';
import { log } from '../utils/monitoring';
import { getSOSPContainerRef } from './wrappers/components';

const getApplicationComponents = async (editorSDK: EditorSDK) => {
  const _menus: CustomMenuData[] = [];
  const menuIds = menus.getMenuIds();
  const [sospContainer, pagesGroupsRefs, _routers, controllers] = await Promise.all([
    getSOSPContainerRef(editorSDK),
    editorSDK.pagesGroup.getAll(APP_TOKEN),
    routers.getAll(editorSDK),
    editorSDK.controllers.listControllers(APP_TOKEN, {}),
  ]);

  for (const menuId of Object.values(menuIds)) {
    const menu = await editorSDK.menu.getById(APP_TOKEN, { menuId });
    if (menu) {
      _menus.push(menu);
    }
  }

  return {
    _routers,
    controllers,
    _menus,
    sospContainer,
    pagesGroupsRefs,
  };
};

async function isReady(editorSDK: EditorSDK, { shouldLog = false } = {}) {
  const { _routers, _menus, sospContainer, pagesGroupsRefs } = await getApplicationComponents(editorSDK);

  if (shouldLog && _routers.length === 2 && _menus.length >= 2 && !sospContainer) {
    log('SOSP container is not apparent when checking whether the application is properly installed', {
      extra: { pagesGroupsRefs: JSON.stringify(pagesGroupsRefs) },
    });
  }

  // Failed installations might have all the components but no pages
  const hasPages = _routers.length === 2 && (_routers[0].pages.length > 0 || _routers[1].pages.length > 0);

  return _routers.length === 2 && _menus.length >= 2 && (sospContainer ? pagesGroupsRefs.length === 1 : hasPages);
}

async function isEmpty(editorSDK: EditorSDK) {
  const { _routers, controllers, _menus, sospContainer, pagesGroupsRefs } = await getApplicationComponents(editorSDK);

  return (
    _routers.length === 0 &&
    controllers.length === 0 &&
    _menus.length === 0 &&
    !sospContainer &&
    pagesGroupsRefs.length === 0
  );
}

export async function isApplicationReady(editorSDK: EditorSDK, props?: { shouldLog: boolean }) {
  return isReady(editorSDK, props);
}

export { isEmpty, getApplicationComponents };
