import { EditorSDK } from '@wix/platform-editor-sdk';
import { APP_TOKEN } from '../constants';

const historyTimeouts: Record<string, NodeJS.Timeout> = {};

async function add(editorSDK: EditorSDK, label: string) {
  await editorSDK.history.add(APP_TOKEN, { label });
}

async function addWithDelay(editorSDK: EditorSDK, label: string, delay = 0) {
  if (historyTimeouts[label]) {
    clearTimeout(historyTimeouts[label]);
  }

  historyTimeouts[label] = setTimeout(() => add(editorSDK, label), delay);
}

export { add, addWithDelay };
